import React, { useState } from "react";
import { Stepper, Step, Input } from "@material-tailwind/react";
import RightArrow from "../assets/icons/Arrow-right.svg";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomInput from "../components/CustomInput";

const validationSchema = Yup.object().shape({
  mobile_no: Yup.string()
    .matches(/^\d{15}$/, "Phone number must be 10 digits")
    .required("Mobile number is required"),
});

export function Registration_1() {
  const Navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(1);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);

  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);
  const registorUser = () => {
  };
  return (
    <Formik
      initialValues={{
        mobile_no: "",
      }}
      validationSchema={validationSchema}
      onSubmit={registorUser}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col justify-center items-center  py-4 px-8 bg-background800 h-screen">
            <div
              className={`flex flex-col bg-neutral-900 w-auto sm:w-[604px] sm:h-[703px]  pt-0 my-11 sm:pt-8 sm:mt-[75px] sm:bg-gradient-to-b from-[#201D1D] via-[#201D1D] to-[#181515] rounded-lg`}
            >
              <div
                className={` hidden  sm:flex justify-center items-center pb-8 `}
              >
                <Stepper
                  className={`w-[200px] `}
                  activeStep={activeStep}
                  isLastStep={(value) => setIsLastStep(value)}
                  isFirstStep={(value) => setIsFirstStep(value)}
                >
                  <Step
                    className={`bg-transparent border-2 border-sky-500 `}
                    style={{
                      borderColor: "#E5D062",
                      backgroundColor: activeStep === 1 ? "#E5D062" : "#181515",
                      color: activeStep === 1 ? "black" : "#E5D062",
                    }}
                    onClick={() => setActiveStep(0)}
                  >
                    1
                  </Step>
                  <Step
                    className={`bg-gold border-2 border-sky-500 `}
                    style={{
                      borderColor: "#E5D062",
                      backgroundColor: activeStep === 2 ? "#E5D062" : "#181515",
                      color: activeStep === 2 ? "black" : "#E5D062",
                    }}
                    onClick={() => setActiveStep(1)}
                  >
                    2
                  </Step>
                  <Step
                    className={`bg-transparent border-2 border-sky-500 `}
                    style={{
                      borderColor: "#E5D062",
                      backgroundColor: activeStep === 3 ? "#E5D062" : "#181515",
                      color: activeStep === 3 ? "black" : "#E5D062",
                    }}
                    onClick={() => setActiveStep(2)}
                  >
                    3
                  </Step>
                </Stepper>
              </div>
              <div
                className={`flex justify-center items-center pt-0 pb-[21px]`}
              >
                <span className={`text-lg text-white`}>
                  Enter your phone number
                </span>
              </div>
              <div
                className={`flex justify-center items-center text-center pb-[21px]`}
              >
                <span className={`text-sm opacity-50 text-Light_Gray`}>
                  We've sent a 4 digit code to your phone number.
                  <br />
                  Please enter the verification code.
                </span>
              </div>

              <div className="flex flex-col gap-[45px] sm:gap-[21px]">
                <div
                  className={`w-full self-start sm:self-center flex flex-col justify-center items-center py-4 `}
                >
                  <div className="flex flex-row gap-1 items-center">
                    <div className={`h-4 w-4   rounded-full`}></div>
                    <span className={`text text-base text-whiteSecondary`}>
                      +951
                    </span>
                    <CustomInput
                      className={`bg-transparent text text-white focus:outline-none text text-base border-b-white100 focus:border-b-gold`}
                      type="number"
                      name="mobile_no"
                      value={values.mobile_no}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter Phone Number"
                      width={`w-full`}
                    />
                  </div>
                  <span className={`text-gold text-xs mt-1`}>
                    {errors.mobile_no && touched.mobile_no && errors.mobile_no}
                  </span>
                </div>
                <div
                  className={`flex justify-end items-end sm:justify-center sm:items-center`}
                >
                  <Button
                    type="submit"
                    text={"Request Code"}
                    isBorder={false}
                    onClick={() => Navigate("/registration_2")}
                    width={"w-[165px] ]"}
                    height={"h-[56px] "}
                    iconRight={RightArrow}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
